<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{
        $route.meta.edit
          ? $t("admin.catalogue.types.edit.title")
          : $t("admin.catalogue.types.create.title")
      }}

      <CButton
        color="primary"
        square
        size="sm"
        class="float-right"
        @click="store"
      >
        {{ $t("buttons.save") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
        {{ errors.message }}
        <ul>
          <li v-for="(error, field) in errors.errors" :key="field">
            {{ error[0] }}
          </li>
        </ul>
      </CAlert>
      <CRow>
        <CCol sm="6">
          <CInput :label="$t('admin.name_en')" v-model="form.name.en" />
        </CCol>
        <CCol sm="6">
          <CInput :label="$t('admin.name_fr')" v-model="form.name.fr" />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6">
          <CInput :label="$t('admin.sub_name_en')" v-model="form.sub_name.en" />
        </CCol>
        <CCol sm="6">
          <CInput :label="$t('admin.sub_name_fr')" v-model="form.sub_name.fr" />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3">
          <CInputFile
            :label="$t('admin.catalogue.types.image_logo')"
            @change="imageLogoUploaded"
          />
          <StorageImage :path="images.image_logo" _class="w-100" />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import StorageImage from "@/components/StorageImage.vue";
import { makeFormData } from "@/helpers";

export default {
  components: { StorageImage },
  data() {
    return {
      isLoading: false,
      errors: [],
      form: {
        name: {},
        sub_name: {}
      },
      images: {}
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      if (this.$route.meta.edit) {
        this.isLoading = true;

        this.$http
          .get(`/api/admin/types/${this.$route.params.id}`)
          .then(response => {
            this.form = response.data.data;
            if (this.form.sub_name === null) {
              this.form.sub_name = {};
            }
            if (this.$route.meta.edit) {
              this.images.image_logo = this.form.image_logo;
              delete this.form.image_logo;
            }

            this.isLoading = false;
          });
      }
    },
    store() {
      this.isLoading = true;
      this.errors = [];

      const url = this.$route.meta.edit
        ? `/api/admin/types/${this.$route.params.id}`
        : `/api/admin/types`;
      this.$http
        .post(url, makeFormData(this.form, this.$route.meta.edit), {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(() =>
          this.$router.push({
            name: "admin.catalogue.types",
            params: { id: this.$route.params.id }
          })
        )
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    },
    imageLogoUploaded(files) {
      this.form.image_logo = files[0];
    }
  },

  metaInfo() {
    return {
      title: this.$route.meta.edit
        ? this.$t("admin.catalogue.types.edit.title")
        : this.$t("admin.catalogue.types.create.title")
    };
  }
};
</script>
