<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{
        $route.meta.edit
          ? $t("admin.catalogue.taxes.edit.title")
          : $t("admin.catalogue.taxes.create.title")
      }}

      <CButton
        color="primary"
        square
        size="sm"
        class="float-right"
        @click="store"
      >
        {{ $t("buttons.save") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
        {{ errors.message }}
        <ul>
          <li v-for="(error, field) in errors.errors" :key="field">
            {{ error[0] }}
          </li>
        </ul>
      </CAlert>
      <CRow>
        <CCol sm="6">
          <CInput :label="$t('admin.name_en')" v-model="form.name.en" />
        </CCol>
        <CCol sm="6">
          <CInput :label="$t('admin.name_fr')" v-model="form.name.fr" />
        </CCol>
        <CCol sm="6">
          <CInput
            :label="$t('admin.catalogue.taxes.value')"
            type="number"
            v-model="form.value"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      errors: [],
      form: {
        name: {},
        value: 0
      }
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      if (this.$route.meta.edit) {
        this.isLoading = true;

        this.$http
          .get(`/api/admin/taxes/${this.$route.params.id}`)
          .then(response => {
            this.form = response.data.data;

            this.isLoading = false;
          });
      }
    },
    store() {
      this.isLoading = true;
      this.errors = [];

      const url = this.$route.meta.edit
        ? `/api/admin/taxes/${this.$route.params.id}`
        : `/api/admin/taxes`;
      const method = this.$route.meta.edit ? "put" : "post";
      this.$http[method](url, this.form)
        .then(() =>
          this.$router.push({
            name: "admin.catalogue.taxes",
            params: { id: this.$route.params.id }
          })
        )
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    }
  },

  metaInfo() {
    return {
      title: this.$route.meta.edit
        ? this.$t("admin.catalogue.taxes.create.title")
        : this.$t("admin.catalogue.taxes.create.title")
    };
  }
};
</script>
