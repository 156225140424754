<template>
  <CCard>
    <CCardHeader>
      {{ $t("admin.catalogue.filters.title") }}

      <CButton
        color="primary"
        variant="outline"
        square
        size="sm"
        class="float-right"
        :to="{
          name: 'admin.filters.create',
          params: { id: this.$route.params.id }
        }"
      >
        {{ $t("buttons.create") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :loading="isLoading"
        :items="items"
        :fields="fields"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        border
        striped
        hover
        pagination
      >
        <template #image="{item}">
          <td class="py-2 text-center">
            <StorageImage :path="item.image" />
          </td>
        </template>

        <template #show_actions="{item}">
          <td class="py-2 text-center">
            <CButton
              color="primary"
              square
              size="sm"
              class="mr-1"
              :to="{ name: 'admin.filters.edit', params: { id: item.id } }"
            >
              <CIcon name="cilPencil" />
            </CButton>

            <CButton
              color="danger"
              square
              size="sm"
              class="mr-1"
              @click="deleteFilter(item.id)"
            >
              <CIcon name="cilTrash"></CIcon>
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import StorageImage from "@/components/StorageImage";

export default {
  components: {
    StorageImage
  },

  data() {
    return {
      isLoading: true,
      items: [],
      fields: [
        { key: "id", label: "ID", _style: "width: 100px" },
        { key: "name", label: this.$t("admin.name") },
        {
          key: "image",
          label: this.$t("admin.catalogue.filters.image"),
          _style: "width: 150px"
        },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 130px"
        }
      ]
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.$http.get(`/api/admin/filters`).then(response => {
        this.items = response.data.data;
        this.isLoading = false;
      });
    },
    deleteFilter(id) {
      this.isLoading = true;
      this.$http.delete(`/api/admin/filters/${id}`).then(() => this.getData());
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.catalogue.filters.title")
    };
  }
};
</script>
